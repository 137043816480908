<!-- (c) 才酝通 -->
<template>
    <div id="content" class="container">
        <nav-header :title="header_title"></nav-header>
        <template v-if="loading && list.length == 0">
            <skeletonPaper></skeletonPaper>
        </template>
        <template v-else-if="list.length > 0">
            <div class="paper">
                <div class="paper-item" v-for="item in list" :key="item.id" @click="goDetail(item.mockPaperId)">
                    <div class="paper-item-comp">
                        <div class="line1">
                            <img src="../../assets/img/mock.png" alt="">
                            <div class="title">{{ item.mockPaper.title }}</div>
                        </div>
                        <div class="line2">
                            <div class="word">总分：{{ item.mockPaper.score }}分</div>
                            <div class="number">最高分：{{ item.maxScore }}分</div>
                        </div>
                    </div>
                </div>
                <div class="drop">
                    <img v-show="loading && !over" src="../../assets/img/loading.gif" />
                    <span v-if="over">已经到底了</span>
                </div>
            </div>
        </template>
        <template v-else-if="list.length == 0">
            <div class="none">
                <none></none>
            </div>
        </template>
    </div>
</template>
<script>
import Vue from 'vue';

import NavHeader from "../../components/nav-header.vue";
import SkeletonPaper from '../../components/skeleton/skeletonPaper';
import None from "../../components/none.vue";

export default {
    components: {
        NavHeader,
        SkeletonPaper,
        None
    },
    data() {
        return {
            header_title: '我的模考',
            pagination: {
                page: 1,
                size: 10,
            },
            over: false,
            loading: false,
            list: [],
        }
    },
    mounted() {
        window.addEventListener("scroll", this.ScrollToBottomEvt, true);
        this.getData();
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    methods: {
        ScrollToBottomEvt() {
            const el = document.getElementById("content");
            const toBottom = el.scrollHeight - window.screen.height - el.scrollTop;
            if (toBottom < 10) {
                this.getData(true);
            }
        },
        getData(more = false) {
            if (this.over) {
                return;
            }
            if (this.loading) {
                return;
            }
            if (more) {
                this.pagination.page++;
            }
            this.loading = true;
            console.log(this.pagination)
            this.$api.User.UserMockPaper(this.pagination).then((res) => {
                let list = res.data.records;
                if (list.length > 0) {
                    this.list.push(...list);
                }
                setTimeout(() => {
                    this.loading = false;
                    if (list.length < this.pagination.size) {
                        this.over = true;
                    }
                }, 200);
            });
        },
        goDetail(pid) {
            this.$router.push({ name: "UserMockRecord", query: { id: pid } });
        }
    }
}
</script>
<style lang="less" scoped>
.paper{
    padding: 0 16px;
    margin-top: 60px;
    .paper-item {
        position: relative;
        width: 100%;
        height: auto;
        float: left;
        box-sizing: border-box;
        margin-bottom: 22px;
        .paper-item-comp {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 126px;
            padding: 25px 16px 22px 16px;
            box-shadow: 0px 4px 8px 0px hsla(0,0%,40%,.1);
            border-radius: 8px;
            .isenter{
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 19px;
                background: rgba(60,167,250,.1);
                border-radius: 0px 8px 0px 8px;
                font-size: 13px;
                font-weight: 400;
                color: #3ca7fa;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .line1 {
                display: flex;
                img{
                    width: 30px;
                    height: 30px;
                }
                .title {
                    flex: 1;
                    font-size: 17px;
                    font-weight: 500;
                    color: #333;
                    line-height: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-left: 10px;
                }
            }
            .line2 {
                display: flex;
                 .word{
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                    line-height: 14px;
                    margin-top: 40px;
                }
                .number{
                    margin-left: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                    line-height: 14px;
                    margin-top: 40px;
                }
            }
            .lock{
                position: absolute;
                bottom: 22px;
                right: 16px;
                width: 16px;
                height: 16px;
                img{
                    width: 16px;
                    height: 16px;
                    border-radius: 0px;
                    overflow: visible;
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out 0s;
                    background-color: transparent;
                }
            }
        }
    }
}
.none {
    margin-top: 120px;
}
.drop {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #cccccc;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.loading{
    width: 100%;
    text-align:center;
    margin-top:10px;
}
</style>
